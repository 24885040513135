import { Component, EventEmitter, input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-footer',
  standalone: true,
  imports: [],
  templateUrl: './modal-footer.component.html',
  styleUrl: './modal-footer.component.scss'
})
export class ModalFooterComponent {
  proceedButtonText = input('Join Chat');
  cancelButtonText = input('Cancel');
  @Output()
  cancelAction = new EventEmitter<void>();
  @Output()
  proceedAction = new EventEmitter<void>();
}
