<div
    class="w-full px-5 py-5 bg-gray-100 border-t border-[#e5e3de] justify-end items-center gap-2.5 inline-flex dark:bg-slate-700 dark:border-[#3a4a61]">
    <div class="justify-start items-start flex">

        <button class="joii-btn-outline-sml-round dark:text-white"
            (click)="cancelAction.emit()">{{cancelButtonText()}}</button>
    </div>
    <div class="justify-start items-start flex">
        <button
            class="joii-btn-primary rounded-lg bg-primary border-primary dark:bg-white dark:border-white dark:text-[#162033]"
            (click)="proceedAction.emit()">{{proceedButtonText()}}</button>
    </div>
</div>
