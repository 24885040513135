<div>
  <dl class="divide-y divide-gray-100">
    <div
      *ngFor="let item of config.items"
      class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
    >
      <dt class="text-sm font-medium leading-6 text-gray-900">
        {{ item.title }}
      </dt>
      <dd
        [ngClass]="item.styleClass ? item.styleClass : 'mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'"
        *ngIf="item.type == DetailsListItemType.Text"
      >
        {{ item.content }}
      </dd>
      <dd
        [ngClass]="item.styleClass ? item.styleClass : 'mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'"
        *ngIf="item.type == DetailsListItemType.Date"
      >
        {{ item.content | date }}
      </dd>
      <dd
        [ngClass]="item.styleClass ? item.styleClass : 'mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'"
        *ngIf="item.type == DetailsListItemType.Decimal"
      >
        {{ item.content | number : "1.2-2" }}
      </dd>
      <img
        data-automationid="details-list-row-image"
        *ngIf="item.type == DetailsListItemType.Image"
        class="flex-auto bg-gray-50"
        [src]="item.content"
        alt=""
      />
    </div>
  </dl>
</div>
