import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, computed, effect, inject, input } from '@angular/core';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { ModalFooterComponent } from "../cdk-dialog-container/modal-footer/modal-footer.component";
import { ChatDataService } from 'src/pms/services/chat.data.service/chat.data.service';
import { ChatAPIService } from 'src/shared/services/chat-api-service/chat-api-service.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';

@Component({
  selector: 'app-chat-handover-summary',
  standalone: true,
  imports: [NgClass, NgIf, DatePipe, ModalFooterComponent],
  templateUrl: './chat-handover-summary.component.html',
  styleUrl: './chat-handover-summary.component.scss'
})
export class ChatHandoverSummaryComponent implements OnInit, OnDestroy {
  darkModeService = inject(DarkModeService)
  chatDataService = inject(ChatDataService)
  chatAPIService = inject(ChatAPIService)
  staffProfileService = inject(StaffProfileDataService)
  toastrService = inject(ToastrService)
  dialogData = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  chatID = computed(() => this.dialogData?.chatID);
  chatMembers = computed(() => this.dialogData?.chatMembers);
  handoverStaffName!: string;
  handoverTime!: string;
  handoverReason!: string;
  handoverMessage!: string;
  subscription: Subscription = new Subscription();

  ngOnInit() {
    this.getHandover()
  }

  cancel() {
    this.dialogRef.close(false);
  }
  joinChat() {
    this.dialogRef.close(true);
  }

  getHandover() {
    this.subscription = this.chatDataService.GetHandover(this.chatID()!).subscribe({
      next: (handover) => {
        this.handoverTime = handover.created_at;
        this.handoverMessage = handover.note;
        this.getHandoverReason(handover.reason_id);
        this.handoverStaffName = this.chatMembers().find((member: any) => member.staff_profile_doc_id === handover.from_staff_doc_id)?.staff_profile_name || "";
      },
      error: (error) => {
        this.toastrService.error(error.error, 'Error getting handover');
      }
    })
  }

  getHandoverReason(id: number) {
    this.subscription = this.chatAPIService.getHandoverReason(id.toString()).subscribe({
      next: (reason) => {
        this.handoverReason = reason.reason;
      },
      error: (error) => {
        this.toastrService.error(error.error, 'Error getting handover reason');
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
