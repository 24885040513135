import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { bookinginternal, entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ChatDataService {
  client = inject(ClientService);

  GetPendingChats(params: entities.GetPendingChatsRequest) {
    return this.client.requestWithClient(client => client.bookinginternal.GetPendingChats(params));
  }

  AddChatConsultation(params: entities.CreateChatConsultationRequest) {
    return this.client.requestWithClient(client => client.consultationinternal.AddChatConsultation(params));
  }

  AddChatParticipant(chatDocID: string, staffProfileID: string) {
    return this.client.requestWithClient(client => client.bookinginternal.AddChatParticipant(chatDocID, staffProfileID));
  }

  GetHandover(chatDocID: string): Observable<bookinginternal.GetHandoverResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.GetHandover(chatDocID));
  }

  CompleteHandover(chatID: string, staffID: string): Observable<void> {
    return this.client.requestWithClient(client => client.bookinginternal.CompleteHandover(chatID, staffID));
  }
}
