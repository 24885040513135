<div class="flex flex-col">
    <div class="p-10 flex-col justify-start items-center gap-5 inline-flex">

        <div class="text-center text-black dark:text-white text-2xl font-bold font-['Inter'] tracking-tight">Join chat
        </div>
        <div class="text-center text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
            {{staffName()}} is already in the chat, do you want to join them?</div>
    </div>

    <app-modal-footer proceedButtonText="Join Chat" (proceedAction)="joinChat()" cancelButtonText="Cancel"
        (cancelAction)="cancel()"></app-modal-footer>
</div>