import { Component, computed, inject } from '@angular/core';
import { ModalFooterComponent } from "../cdk-dialog-container/modal-footer/modal-footer.component";
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-chat-join-in-progress',
  standalone: true,
  imports: [ModalFooterComponent],
  templateUrl: './chat-join-in-progress.component.html',
  styleUrl: './chat-join-in-progress.component.scss'
})
export class ChatJoinInProgressComponent {
  dialogData = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  staffName = computed(() => this.dialogData?.staffName || '');

  cancel() {
    this.dialogRef.close(false);
  }
  joinChat() {
    this.dialogRef.close(true);
  }
}
