import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { bookinginternal } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})

export class AppointmentsDataService {

  constructor(private client: ClientService) { }

  GetAppointment(appointmentId: string, params: bookinginternal.GetAppointmentRequest): Observable<bookinginternal.GetAppointmentResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.GetInternalAppointment(appointmentId, params));
  }

  ListAppointments(request: bookinginternal.ListAppointmentsV2Request): Observable<bookinginternal.ListAppointmentsV2Response> {
    return this.client.requestWithClient(client => client.bookinginternal.ListAppointmentsV2(request));
  }

  UpdateAppointmentConsultStatus(appointmentId: string, params: bookinginternal.UpdateAppointmentConsultStatusRequest): Observable<any> {
    return this.client.requestWithClient(client => client.bookinginternal.UpdateAppointmentConsultStatus(appointmentId, params));
  }

  UnAssignStaffFromAppointment(appointmentID: string): Observable<any> {
    return this.client.requestWithClient(client => client.bookinginternal.UpdateAppointmentStaffProfile(appointmentID, { staff_profile_id: undefined }));
  }

  AssignStaffToAppointment(staffID: string, appointmentID: string): Observable<any> {
    return this.client.requestWithClient(client => client.bookinginternal.UpdateAppointmentStaffProfile(appointmentID, { staff_profile_id: staffID }));
  }

  CancelAppointment(appointmentID: string, source: string): Observable<any> {
    return this.client.requestWithClient(client => client.bookinginternal.CancelAppointment(appointmentID, { source: source }));
  }

  MarkAppointmentAsDidNotAttend(appointmentID: string): Observable<any> {
    return this.client.requestWithClient(client => client.bookinginternal.MarkAppointmentAsDidNotAttend(appointmentID));
  }
}
