import { Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { accountinternal, entities } from 'src/shared/services/client/client';
import { PrescribingEligibilityPillComponent } from "../prescribing-eligibility-pill/prescribing-eligibility-pill.component";
import { LucideAngularModule } from 'lucide-angular';
import { findIndex, some } from 'lodash';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ApplyPipe, CallPipe } from 'ngxtension/call-apply';
import { SvgIconComponent } from 'angular-svg-icon';
import { filter, map, Observable, startWith, Subject, tap, throttleTime, timer } from 'rxjs';
import { derivedAsync } from 'ngxtension/derived-async';
import { HumanizeTimePipe } from 'src/shared/pipes/humanize-time/humanize-time.pipe';
import { DefaultDialogConfig, NoPaddingDialogConfig } from 'src/shared/config/dialog.config';
import { ChatHandoverSummaryComponent } from '../chat-handover-summary/chat-handover-summary.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import { ChatJoinInProgressComponent } from '../chat-join-in-progress/chat-join-in-progress.component';

@Component({
  selector: 'app-appointment-chat-card',
  standalone: true,
  imports: [PrescribingEligibilityPillComponent, LucideAngularModule, TitleCasePipe, CallPipe, SvgIconComponent, HumanizeTimePipe, NgIf, NgFor],
  templateUrl: './appointment-chat-card.component.html',
  styleUrl: './appointment-chat-card.component.scss',
})
export class AppointmentChatCardComponent {
  dialog = inject(Dialog);
  handoverTrigger = new Subject<void>();
  joinExistingTrigger = new Subject<void>();
  handoverDialog = toSignal(this.getHandoverDialog());
  joinExistingDialog = toSignal(this.getJoinExistingDialog());
  chat = input.required<entities.ChatResponse>();
  staffUserId = input<string>();
  expanded = input<boolean>(false);
  loadingCreateConsult = input<boolean>(false);
  docID = computed(() => this.chat().doc_id);
  channelID = computed(() => this.chat().channel_id);
  status = computed(() => this.chat().status);
  statusAction = computed(() => this.getStatusAction(this.status()));
  pet = computed(() => this.chat().pet);
  user = computed(() => this.chat().user);
  participants = computed(() => this.chat().participants);
  channelType = computed(() => this.chat().channel_type);
  business = computed(() => this.chat().business);
  lastUpdated = computed(() => this.chat().updated_at);
  createdAt = computed(() => this.chat().created_at.utc);

  staffCount = computed(() => this.participants().filter((participant) => participant.staff_profile_doc_id).length);
  staffNames = computed(() => this.participants().filter((participant) => participant.staff_profile_doc_id).map((participant) => participant.staff_profile_name));
  isStaffAssignedToChat = computed(() => findIndex(this.participants(), { "staff_profile_doc_id": this.staffUserId() }) !== -1);
  isAnyStaffAssigned = computed(() => some(this.participants(), (participant) => participant?.staff_profile_doc_id));
  now = derivedAsync(() => this.getCurrentTime(), { initialValue: new Date().toISOString() });
  handoverClosed = derivedAsync(() => this.handleHandoverDialogClose(this.handoverDialog()));
  joinExistingClosed = derivedAsync(() => this.handleJoinExistingDialogClose(this.joinExistingDialog()));
  // TODO update this when we know how we will detect a handover

  isHandover = computed(() => this.chat().status === 'handover');

  @Output() startConsultation = new EventEmitter<entities.ChatResponse>();
  @Output() continueConsultation = new EventEmitter<entities.ChatResponse>();
  @Output() joinConsultation = new EventEmitter<entities.ChatResponse>();
  @Output() joinHandoverConsultation = new EventEmitter<entities.ChatResponse>();
  @Output() onToggleExpanded: EventEmitter<any> = new EventEmitter<any>()

  toggleExpanded() { this.onToggleExpanded.emit() }

  handleStartConsultation(chat: entities.ChatResponse) {
    if (this.isHandover()) {
      this.handoverTrigger.next();
    }
    else {
      this.startConsultation.emit(chat);
    }
  }

  handleContinueConsultation(chat: entities.ChatResponse) {
    if (this.isHandover()) {
      this.handoverTrigger.next();
    } else {
      if (!this.isStaffAssignedToChat()) {
        this.joinExistingTrigger.next();
      } else {
        this.continueConsultation.emit(chat);
      }
    }
  }

  handleJoinConsultation(chat: entities.ChatResponse) {
    this.joinExistingTrigger.next();
  }

  handleJoinHandoverConsultation(chat: entities.ChatResponse) {
    if (!this.isHandover()) return;

    this.handoverTrigger.next();
  }

  getStatusAction(status: string | undefined): string | undefined {
    switch (status) {
      case 'pending':
      case 'curious':
        return 'start';
      case 'active':
      case 'frozen':
        return 'continue';
      case 'handover':
        return 'handover';
      default:
        return undefined;
    }
  }

  petDetailsDescription(pet: accountinternal.PetResponse): string {
    if (!pet) return '';

    const parts: string[] = [];

    // Species
    if (typeof pet.species === 'string' && pet.species.trim()) {
      parts.push(pet.species);
    }

    // Gender (neutered)
    if (typeof pet.gender === 'string' && pet.gender.trim()) {
      let genderDescription = pet.gender;
      if (pet.neutered) {
        genderDescription += ' (neutered)';
      }
      parts.push(genderDescription);
    }

    // Breed
    if (pet.breed?.name && typeof pet.breed.name === 'string' && pet.breed.name.trim()) {
      parts.push(pet.breed.name);
    }

    // Age
    if (pet.age?.years || pet.age?.months) {
      const years = pet.age?.years ?? 0;
      const months = pet.age?.months ?? 0;

      let ageDescription = '';
      if (years > 0) {
        ageDescription += `${years} year${years > 1 ? 's' : ''}`;
      }
      if (months > 0) {
        if (ageDescription) ageDescription += ', ';
        ageDescription += `${months} month${months > 1 ? 's' : ''}`;
      }

      if (ageDescription) {
        parts.push(`${ageDescription}`);
      }
    }

    // Weight
    if (pet.weight?.kg) {
      const weightString = `${pet.weight.kg}kg`;
      parts.push(weightString);
    }

    // Join parts with appropriate separators
    return parts.join(', ').replace(/,([^,]*)$/, ' and$1');
  }

  /**
   * Observable to get an updated time every 5 seconds
   * @returns Observable of ISO string of the current time
   */
  getCurrentTime(): Observable<string> {
    return timer(0, 5000).pipe(
      map(() => new Date().toISOString())
    );
  }
  getHandoverDialog(): Observable<DialogRef<any, any> | null> {
    return this.handoverTrigger.asObservable().pipe(
      throttleTime(1000),
      map(() => {
        return this.dialog.open(
          ChatHandoverSummaryComponent,
          {
            ...NoPaddingDialogConfig,
            data: {
              chatID: this.docID(),
              chatMembers: this.participants()
            }
          });
      }),
      startWith(null)
    );
  }
  getJoinExistingDialog() {
    return this.joinExistingTrigger.asObservable().pipe(
      throttleTime(1000),
      map(() => {
        return this.dialog.open(
          ChatJoinInProgressComponent,
          {
            ...NoPaddingDialogConfig,
            data: {
              staffName: this.staffNames()
            }
          });
      }),
      startWith(null)
    );
  }

  handleHandoverDialogClose(dialogRef?: DialogRef<any, any> | null) {
    return dialogRef?.closed.pipe(
      filter((data) => data),
      tap(() => {
        switch (this.statusAction()) {
          case 'handover':
            this.joinHandoverConsultation.emit(this.chat());
            break;
        }
      }),
      startWith(null)
    );
  }

  handleJoinExistingDialogClose(dialogRef?: DialogRef<any, any> | null) {
    return dialogRef?.closed.pipe(
      filter((data) => data),
      tap(() => {
        this.joinConsultation.emit(this.chat());
      }),
      startWith(null));

  }
}


