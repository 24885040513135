<span class="sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6"></span>
<app-label [label]="label"></app-label>
<div class="">
  <textarea [rows]="rows" [placeholder]="placeholder || ''" [id]="name" [readonly]="readonly" [disabled]="disabled"
    [ngModel]="value" [ngModelOptions]="ngModelOptions" (ngModelChange)="onInputChange($event)"
    (blur)="onInputBlur($event)"
    class="joii-input block w-full rounded-md py-1.5 text-gray-900 dark:text-white shadow-sm border border-stone-200 ring-gray-300 placeholder:text-gray-400 focus:border-primary-900 sm:text-sm sm:leading-6 disabled:bg-gray-200 disabled:cursor-not-allowed resize">
    </textarea>
</div>
<app-validation *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted"
  [errormessage]="errormessage"></app-validation>