import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { Observable, switchMap } from "rxjs";
import { entities, bookinginternal } from "../client/client";

@Injectable({
  providedIn: 'root'
})
export class ChatAPIService {
  clientService = inject(ClientService);

  public getStaffToken(staffID: string): Observable<bookinginternal.GetStaffTokenResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.GetStaffToken(staffID))
    )
  }

  public assignChat(staffID: string, chatID: string): Observable<void> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.AddChatParticipant(chatID, staffID))
    )
  }

  public freezeChat(chatID: string): Observable<void> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.FreezeChannel(chatID))
    )
  }

  public updateChatConsultStatus(chatID: string, status: bookinginternal.UpdateChatConsultStatusRequest): Observable<any> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.UpdateChatConsultStatus(chatID, status))
    )
  }

  public handoverChat(chatID: string, handover: bookinginternal.HandoverChatRequest): Observable<any> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.HandoverChat(chatID, handover))
    )
  }

  public getHandoverReasons(): Observable<bookinginternal.GetHandoverReasonsResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.GetHandoverReasons())
    )
  }

  public getHandoverReason(id: string): Observable<entities.HandoverReason> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.GetHandoverReason(id))
    )
  }

  public createHandoverReason(reason: string): Observable<bookinginternal.AddHandoverReasonResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.AddHandoverReason({ reason }))
    )
  }

  public updateHandoverReason(id: string, reason: string): Observable<void> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.UpdateHandoverReason(id, { reason }))
    )
  }

  public deleteHandoverReason(id: string): Observable<void> {
    return this.clientService.client.pipe(
      switchMap(client => client.bookinginternal.DeleteHandoverReason(id))
    )
  }

  public GetAiChatSummary(chat_doc_id: string) {
    return this.clientService.requestWithClient(client => client.vetaichatinternal.GetChatSummary(chat_doc_id));
  }
}
