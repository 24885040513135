@if (consultationHistory.length > 0) {
  <section>
    @for (history of consultationHistory; track history) {
      <article class="bg-gray-50 dark:bg-slate-700 mb-3 p-3 rounded">
        <header class="pt-1 mb-2">
          <span
            class="text-gray-500 dark:text-gray-200 text-xs font-semibold font-['Inter'] leading-none tracking-tight">
            {{history.appointment?.actual_end_time || history.chat?.actual_end_time.local | date: 'd MMM, yyyy HH:MM' | uppercase }}
          </span>
          <span class="float-right">
            <img [src]="history.appointment?.staff_profile_photo_url || extractStaff(history.chat.participants)[0].staff_profile_image" alt="User Profile"
                 class="w-[18px] h-[17px] rounded-full mr-2 inline-block">
            <span class="text-slate-800 dark:text-white text-[15px] font-semibold font-['Inter'] tracking-tight" [title]="history.appointment?.staff_profile_name || extractStaff(history.chat.participants)[0].staff_name">
              {{history.appointment?.staff_profile_name || extractStaff(history.chat.participants)[0].staff_name | staffName}}
            </span>
          </span>
        </header>
        <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
          <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">
            {{history | venomCodeExtractor}}
          </span>
          @if (!history.expanded) {
            <app-rich-text-viewer [richText]="history.notes.substring(0,200)"></app-rich-text-viewer>
            @if (history.notes.length > 200) {<span>...</span>}
          }
          @if (history.expanded) {
            <app-rich-text-viewer [richText]="history.notes"></app-rich-text-viewer>
          }
        </section>
        <main *ngIf="history.expanded">
          <hr class="mb-2" />
          <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Product Recommendations</span>
            @if (history.recommended_products.length > 0) {
              @for (product of history.recommended_products; track $index) {
                <div class="flex flex-col">
                  <span>
                    <strong>Product Name: </strong> {{product.product_name}}
                    <a [href]="product.product_url" target="_blank" title="Link to product">
                      <lucide-angular
                        class="inline-block mr-0.5 relative -top-5-px"
                        name="link"
                        size="15"
                        strokeWidth="3"
                      ></lucide-angular>
                    </a>
                  </span>
                  <a><strong>Quantity: </strong> {{product.quantity}}</a>
                  <a><strong>Usage: </strong> {{product.usage}}</a>
                </div>
              }
            }
            @if (history.recommended_products.length === 0) {
              <p>No recommended products</p>
            }
          </section>
          <hr class="mb-2" />
          @if (history.healthCheckData) {
            <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Health Score Survey</span>
              <app-health-check-data [healthCheckData]="history.healthCheckData"></app-health-check-data >
            </section>
          } @else {
            <section *ngIf="history.appointment" class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Owner Observations</span>
              <app-owner-observations [appointmentDetails]="history.appointment"></app-owner-observations>
            </section>
          }

          <hr class="mb-2" />
          <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Assessment</span>
            <article class="joii-raised-well p-3 rounded">
              <app-assessment-details [consultation]="history"></app-assessment-details>
            </article>
          </section>
          <hr class="mb-2" />
          @if(history.outcome){
            <section class="mb-2">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Outcomes</span>
              <article class="joii-raised-well p-3 rounded">
                <app-formio-submission [SubmissionId]="history.outcome.doc_id"></app-formio-submission>
              </article>
            </section>
            <hr class="mb-2" />
          }
          <section class="mb-2">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Note to customer</span>
            <app-rich-text-viewer [richText]="history.treatment_plan"></app-rich-text-viewer>
          </section>

          @if (history.patient_media_files?.length) {
            <section class="joii-raised-well rounded-t-lg border mb-2 mt-5">
              <div class="p-5 flex w-full flex-wrap flex-row">

                <div class="flex w-full flex-wrap flex-row">
                  @for (file of history.patient_media_files; track $index) {
                    @if (file.media_type === 'photo') {
                      <img [src]="file.file_url" class="cursor-pointer max-w-1/4 p-1 border-blue-600 rounded-lg overflow-hidden" (click)="showImagesModal(history.patient_media_files)">
                    }
                  }
                </div>

                <div class="flex w-full flex-wrap flex-row">
                  @for (file of history.patient_media_files; track $index) {
                    @if (file.media_type === 'video') {
                      <video class="cursor-pointer max-w-1/4 m-1 border-blue-600 rounded-lg overflow-hidden" controls>
                        <source [src]="file.file_url" type="video/{{file.file_url| fileExtension: 'extension'}}" />
                      </video>
                    }
                  }
                </div>

                <div>
                  @for (file of history.patient_media_files; track $index) {
                    @if (file.media_type === 'lab_report') {
                      <div class="text-blue-900 bg-slate-100 rounded-lg p-1 m-1">
                        <button class="" (click)="openDocument(file.file_url)">{{file.file_name}}</button>
                      </div>
                    }
                  }
                </div>

              </div>
            </section>
          }
        </main>
        <footer>
          <button class="joii-btn-outline-sml-round" (click)="toggleExpanded(history)">
            Show @if (history.expanded) {Less} @if (!history.expanded) {More}
          </button>
        </footer>
      </article>
  }
    <footer>
      <div class="pb-2"><span>Displaying records 1-{{consultationHistory.length}} of {{paginationConfig.total}}</span></div>
      @if (paginationConfig.total > paginationConfig.RequestedItemsPerPage && !(consultationHistory.length === paginationConfig.total)) {
        <button class="joii-btn-outline-sml-round" [disabled]="loadingMore" (click)="fetchMoreNow()">
          Load More
        </button>
      }
    </footer>
  </section>
}
@if (consultationHistory.length === 0) {
  <section>
    <article><p>No Consultation History</p></article>
  </section>
}

<app-image-carousel-modal></app-image-carousel-modal>
