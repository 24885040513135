@if(chat()) {
<section class="appointment-card-holder shadow-md mt-2 border-amber-400 rounded overflow-hidden relative"
    [class.border-l-4]="expanded()">
        <div class="absolute right-4 top-4">
          @if(staffCount() > 1) {
          <div class="relative inline-block cursor-default border border-[#344d80] dark:border-white p-2 rounded-lg group">
            <div class="flex justify-end items-center gap-2 px-3">
              <span>{{status() | titlecase}} - {{staffCount() || 0}} clinicians</span>
            </div>
            <ul class="absolute left-0 top-full hidden w-full border border-gray-300 bg-white text-black z-50 rounded-lg group-hover:block">
              <li
                *ngFor="let staffName of staffNames()"
                class="px-3 py-2 border-gray-200 hover:bg-gray-100"
              >
                {{staffName}}
              </li>
            </ul>
          </div>
          } @else {
          <p class="px-3 py-2.5 rounded-lg border border-[#344d80] dark:border-white justify-end items-center gap-2 flex">
            @if(status() && status().length) {
            <span class="text-[#344d80] dark:text-white text-[15px] font-normal font-['Inter'] tracking-tight">{{status() | titlecase}}</span>
            }
            <span class="text-[#344d80] dark:text-white text-[15px] max-w-10 text-nowrap sm:max-w-15 md:max-w-32 lg:max-w-64 text-ellipsis overflow-hidden font-semibold font-['Inter'] tracking-tight">&nbsp;{{staffNames().join(', ') || 'Unassigned'}}</span>
          </p>
          }
        </div>
      <article class="p-5 pb-2 bg-amber-50 dark:bg-[#3c150c]" (click)="toggleExpanded()">
        <div class="profile-image-holder mr-2 rounded-full overflow-hidden">
            @if(pet() && pet().photo_url) {
            <span class="profile-image"><img [src]="pet().photo_url" [alt]="pet().name" /></span>
            }
            @else if (!pet().photo_url && pet().name) {
            <div
                class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-[#e1edf8] dark:bg-[#e1edf8] rounded-full">
                <span class="text-2xl text-primary dark:text-[#162033]">{{pet().name[0]}}</span>
            </div>
            }
        </div>
        <div class="appointment-details-header align-top">
            <p class="inline-flex gap-1 justify-start items-center">
                <span class="px-1 py-0.5 bg-[#e9fae2] text-[#222222] rounded">Vet</span>
                <span
                    class="text-[#222222] dark:text-white text-base font-bold font-['Inter'] leading-normal tracking-tight">Online
                    chat</span>
                <span
                    class="text-[#222222] dark:text-white text-base font-bold font-['Inter'] leading-normal tracking-tight lowercase">&nbsp;|
                    created {{createdAt() | humanizeTime : now()}}</span>
                <lucide-angular name="messages-square" [strokeWidth]="1" class="text-[#222222] dark:text-white" />
            </p>
            @if(isHandover()){
            <div class="h-7 justify-start items-start inline-flex pl-1">
                <div class="p-1.5 bg-amber-400 rounded-md justify-start items-center gap-px flex">
                    <div class="px-1 justify-start items-center gap-[7px] flex">
                        <div class="text-[#222222] text-xs font-medium font-['Inter'] leading-none tracking-tight">
                            Handover</div>
                    </div>
                </div>
            </div>
            }
            @if(user() && pet() && user().first_name && pet().name) {
            <p
                class="text-[#5e544e] dark:text-[#e5e3de] text-base font-normal font-['Inter'] leading-normal tracking-tight">
                <span>{{user().first_name}} {{user().last_name}}</span>
                <span>&nbsp;chatting about&nbsp;</span>
                <span>{{pet().name}}</span>
            </p>
            }
        </div>
        <div class="flex gap-x-1 items-center mt-2 min-h-7">
            <app-prescribing-eligibility-pill [postcode]="user().post_code || ''"
                [backgroundStyleClasses]="'bg-[#5b93d6] dark:bg-[#5b93d6]'" [textStyleClasses]="'text-white'" />
            @if (pet() && pet().triage) {
            <div class="justify-start items-start inline-flex h-7">
                <div class="p-1.5 bg-[#00e0ff] rounded-[999px] justify-start items-center gap-px flex">
                    <div class="px-[2.50px] justify-start items-center gap-[7px] flex">
                        <div class="text-[#212e4a] text-xs font-medium font-['Inter'] leading-none tracking-tight">
                            Waggle 15min</div>
                    </div>
                </div>
            </div>
            }
        </div>
        </article>
        @if (expanded()) {
            <article class="pl-5 pr-5 pb-5 bg-amber-50 dark:bg-[#3c150c]">
                <hr />
                <section class="mt-2">
                    <p class="text-[#4779c9] dark:text-[#a5cbeb]  text-base font-medium font-['Inter'] leading-normal tracking-tight">{{pet() | call:petDetailsDescription}}</p>
                    @if (pet() && pet().policy_active && business()?.partner_integration) {
                        @switch (business()?.partner_integration) {
                            @case ("ANIMALFRIENDS") {
                                <span class="h-5 ml-1 inline-block mt-2.5">
                                    <svg-icon src="assets/svgs/brand-afi.svg"/>
                                </span>
                            }
                            @case ("ASDA") {
                                <span class="h-5 ml-1 inline-block mt-2.5">
                                    <svg-icon src="assets/svgs/brand-asda.svg"/>
                                </span>
                            }
                            @case ("PURELYPETS") {
                                <span class="h-5 ml-1 inline-block mt-2.5">
                                    <svg-icon src="assets/svgs/brand-purely.svg"/>
                                </span>
                            }
                            @case ("WAGGLE") {
                                <span class="h-5 ml-1 inline-block mt-2.5">
                                    <svg-icon src="assets/svgs/brand-waggel.svg"/>
                                </span>
                            }
                            @default {
                                <span class="inline-block text-[#222222] dark:text-white">{{
                                    business()?.name
                                }}</span>
                            }
                        }
                    }
                </section>
                <footer class="text-right mt-2">
                    @if (statusAction() === 'continue') {
                        <button
                        *ngIf="isStaffAssignedToChat()"
                        class="joii-btn-primary rounded-lg bg-primary border-primary dark:bg-white dark:border-white dark:text-[#162033]"
                        [disabled]="loadingCreateConsult()"
                        data-automationid="appointment-card-continue-call-btn"
                        (click)="handleContinueConsultation(chat())"
                        >
                        Resume chat
                        </button>
                        <button
                        *ngIf="!isStaffAssignedToChat()"
                        class="joii-btn-primary rounded-lg bg-primary border-primary dark:bg-white dark:border-white dark:text-[#162033]"
                        [disabled]="loadingCreateConsult()"
                        data-automationid="appointment-card-join-chat-btn"
                        (click)="handleJoinConsultation(chat())"
                        >
                        Join chat
                        </button>
                    }
                    @if (statusAction() === 'start') {
                        <button
                        class="joii-btn-primary rounded-lg bg-primary border-primary dark:bg-white dark:border-white dark:text-[#162033]"
                        [disabled]="loadingCreateConsult() || (isAnyStaffAssigned() && !isStaffAssignedToChat())"
                        data-automationid="appointment-card-start-call-btn"
                        (click)="handleStartConsultation(chat())">Start chat</button>
                    }
                    @if (statusAction() === 'handover') {
                        <button
                        class="joii-btn-primary rounded-lg bg-primary border-primary dark:bg-white dark:border-white dark:text-[#162033]"
                        [disabled]="loadingCreateConsult()"
                        data-automationid="appointment-card-handover-btn"
                        (click)="handleJoinHandoverConsultation(chat())">Read handover notes</button>
                    }
                </footer>
            </article>
        }
</section>
}
