<div class="flex flex-col min-w-[500px]">
    <div class="p-10 flex-col justify-start items-center gap-5 inline-flex"
        [ngClass]="{'dark': darkModeService.darkModeOn()}">

        <div class="text-center text-black dark:text-white text-2xl font-bold font-['Inter'] tracking-tight">
            Handover chat
        </div>
        <div class="text-center text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
            This
            is what the previous clinician said</div>
        <div *ngIf="handoverReason; else loading"
            class="h-[102px] px-5 py-[15px]  rounded-[10px] shadow-sm border border-[#e5e3de] justify-start items-start gap-[15px] inline-flex">
            <div class="grow shrink basis-0 h-[72px] justify-start items-center gap-[15px] flex">
                <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div
                        class="self-stretch text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
                        {{handoverStaffName}} | {{handoverTime | date:'shortTime'}}</div>
                    <div
                        class="self-stretch text-[#222222] dark:text-white text-base font-medium font-['Inter'] leading-normal tracking-tight">
                        <p><strong>Reason: </strong> {{handoverReason}}</p>
                        <p>{{handoverMessage}}</p>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #loading><p>Loading...</p></ng-template>
    </div>
    <app-modal-footer proceedButtonText="Join Chat" (proceedAction)="joinChat()" cancelButtonText="Cancel"
        (cancelAction)="cancel()">
    </app-modal-footer>
</div>
