import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges, OnChanges, ElementRef, inject } from "@angular/core";
import { Subscription, map, switchMap } from "rxjs";
import { bookinginternal } from "src/shared/services/client/client";
import { ConsultationService } from "src/shared/services/consultations/consultations.service";
import { HealthCheckData } from "src/shared/components/health-check-data/health-check-data.interface";
import { doc, Firestore, getDoc } from "@angular/fire/firestore";

@Component({
    selector: 'app-more-details-sidebar',
    templateUrl: './more-details.component.html'
})
export class MoreDetailsComponent implements OnDestroy, OnChanges {

    petConsultationsHistory: Array<any> = [];
    petConsultationSubscription?: Subscription;
    @Input()
    appointmentDetails?: bookinginternal.GetAppointmentResponse
    @Output()
    hideSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    startConsult: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    continueConsult: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() staffProfile: any;
    loadingHistory: boolean = false;
    petFullHistoryPaginationConfig: {total: number, currentPage: number, pages: number, RequestedItemsPerPage: number, RequestedPageNumber: number, OrderBy: string} = {total: 0, currentPage: 1, pages: 0, RequestedItemsPerPage: 10, RequestedPageNumber: 1, OrderBy: 'desc'};
    healthCheckData!: HealthCheckData;
    private firestore: Firestore = inject(Firestore);
    collection = 'health-checks';

    constructor(private consultationService: ConsultationService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["appointmentDetails"] && changes["appointmentDetails"].currentValue) {
            this.petConsultationsHistory = [];
            this.petFullHistoryPaginationConfig.RequestedPageNumber = 1;
            this.loadMoreDetails();
        }
    }


    loadMoreDetails() {
      this.loadingHistory = true;
      if (this.appointmentDetails) {
        this.getHealthCheckData(this.appointmentDetails?.doc_id)
      }

      this.petConsultationSubscription = this.consultationService.GetPetFullConsultationsHistory(this.petFullHistoryPaginationConfig, this.appointmentDetails!.pet_id).subscribe({
        next: data => {
          console.log(data);
          this.handleHistoryResponse(data);
        }
      })
    }

    handleGetMoreHistory() {
      this.petFullHistoryPaginationConfig.RequestedPageNumber = this.petFullHistoryPaginationConfig.currentPage + 1;
      this.loadMoreDetails();
    }

    handleHistoryResponse(response: any) {
      if (response?.consultations_history.length > 0) {
        response?.consultations_history.forEach((item: any) => {item.expanded = false})
      }
      if (this.petConsultationsHistory.length > 1) {
        response?.consultations_history.forEach((item: any) => {
          if (this.petConsultationsHistory.findIndex(check => check.appointment_id === item.appointment_id) === -1) {
            this.petConsultationsHistory.push(item)
          }
        });
      } else {
        this.petConsultationsHistory = response?.consultations_history;
        this.petConsultationsHistory.forEach((item: any) => {
          if (item.appointment_id != undefined && item.appointment_id.length > 0) {
            const docRef = doc(this.firestore, `${this.collection}/${item.appointment_id}`);
            getDoc(docRef).then((docSnap) => {
              if (docSnap.exists()) {
                item.healthCheckData = docSnap.data() as HealthCheckData;
              }
            }).catch((error) => {
              console.error('Error fetching document:', error);
            });
          }
        })
      }
      console.log()
      this.petFullHistoryPaginationConfig.total = response?.pagination.total;
      this.petFullHistoryPaginationConfig.pages = response?.pagination.pages;
      this.petFullHistoryPaginationConfig.currentPage = response?.pagination.current_page;
      this.loadingHistory = false;
    }

    showLess() {
        this.hideSidebar.emit(true);
    }

    handleContinueConsultation() {
        this.continueConsult.emit(true);
    }

    handleStartConsultation() {
        this.startConsult.emit(true);
    }

    getHealthCheckData(appointmentId: string): void {
      if (appointmentId != undefined && appointmentId.length > 0) {
        const docRef = doc(this.firestore, `${this.collection}/${appointmentId}`);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            this.healthCheckData = docSnap.data() as HealthCheckData;
          }
        }).catch((error) => {
          console.error('Error fetching document:', error);
        });
      }
    }

    ngOnDestroy(): void {
        this.petConsultationSubscription?.unsubscribe();
    }
}
